<template>
  <div ref="mapRef" style="width: 100%; height: 100%" id="map">
    <slot v-if="ready"></slot>
  </div>
</template>
<script lang="ts">
import { defineComponent, ref, provide, onMounted, onBeforeUnmount } from "vue";
import { MapSymbol } from "./utils";

export default defineComponent({
  name: "Map",
  props: {
    center: {
      type: [Object, Array],
      default: () => [22.6279745, 120.3103147]
    },
    zoom: {
      type: Number,
      default: 12
    }
  },
  setup(props) {
    const mapRef = ref(null);
    const ready = ref(false);
    const instance = ref<any>();

    provide(MapSymbol, instance);
    onMounted(async () => {
      const { map } = await import("leaflet");

      instance.value = map(mapRef.value!).setView(
        props.center as any,
        props.zoom
      );
      ready.value = true;
    });

    onBeforeUnmount(() => {
      instance.value.remove();
    });
    return {
      instance,
      mapRef,
      ready
    };
  }
});
</script>
<style>
#map {
  height: "100%";
  width: "100%";
}
</style>
