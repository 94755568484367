
import { defineComponent, inject, Ref, onMounted, onBeforeUnmount } from "vue";
import { MapSymbol } from "./utils";

export default defineComponent({
  name: "Tile",
  props: {
    server: {
      type: String,
      default: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
    },
  },
  setup(props) {
    const map = inject<Ref<any>>(MapSymbol)!;
    let layer: any;

    onMounted(async () => {
      const { tileLayer } = await import("leaflet");

      layer = tileLayer(props.server).addTo(map.value);
    });

    onBeforeUnmount(() => {
      if (layer) map.value.removeLayer(layer);
    });

    return {};
  },
});
