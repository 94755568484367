
import { defineComponent, ref, watch, onMounted, reactive } from "vue";
import CloudFun, { Condition, Operator } from "@cloudfun/core";
import PieChart from "@/components/pie-chart/Main.vue";
import BarChart from "@/components/bar-chart/Main.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Map from "@/components/map/Main.vue";
import MapTile from "@/components/map/Tile.vue";
import MapHeatmap from "@/components/map/Heatmap.vue";
import { PeopleFlow, COLOR_PALETTE } from ".";
import router from "@/router";

export default defineComponent({
  components: {
    PieChart,
    BarChart,
    Loading,
    Map,
    MapTile,
    MapHeatmap
  },
  setup() {
    if (!CloudFun.user.Id) router.push("/login");
    const model = CloudFun.current?.model;
    const map = ref(null);
    const selectedHotspotId = ref(0);
    const selectedHotspotIdOnMap = ref(0);
    const peopleFlows = ref<PeopleFlow[]>([]);
    const heatmapPoints = ref<number[][]>([]);

    const now = ref(new Date());
    const lastHourStr = `${now.value.getHours() -
      1}:00~${now.value.getHours()}:00`;
    const lastHourPaddingStr = (now.value.getHours() - 1)
      .toString()
      .padStart(2, "0");
    const nowHourStr = `${now.value.getHours()}:00~${now.value
      .getHours()
      .toString()
      .padStart(2, "0")}:${now.value
      .getMinutes()
      .toString()
      .padStart(2, "0")}`;

    const load = reactive({
      isLoading: true,
      fullPage: false
    });

    const pieChart = reactive({
      width: 0,
      height: 0,
      data: {
        labels: [] as string[],
        datasets: [
          {
            data: [] as number[],
            backgroundColor: COLOR_PALETTE,
            // hoverBackgroundColor: ["#26a1ff", "#ff2626"],
            borderWidth: 5,
            borderColor: cash("html").hasClass("dark") ? "#303953" : "#fff"
          }
        ]
      }
    });

    const chartBackgroundColors = [
      "rgba(255, 99, 132, 0.2)",
      "rgba(75, 192, 192, 0.2)"
    ];

    const borderColors = ["rgb(255, 99, 132)", "rgb(75, 192, 192)"];

    const barChart = reactive({
      width: 0,
      height: 0,
      data: {
        labels: [
          "00",
          "01",
          "02",
          "03",
          "04",
          "05",
          "06",
          "07",
          "08",
          "09",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23"
        ],
        datasets: [
          {
            label: "人數",
            data: [] as any[],
            borderWidth: 1,
            borderColor: [] as string[],
            backgroundColor: [] as string[],
            pointBorderColor: "transparent"
          }
        ]
      }
    });

    watch(selectedHotspotId, id => {
      if (!id) return;

      const flow = peopleFlows.value.find(e => e.HotspotId === id);
      console.log("🚀 ~ file: Daily.vue ~ line 234 ~ setup ~ flow", flow);

      if (flow) {
        barChart.data.datasets[0].data = [
          flow.Hour00,
          flow.Hour01,
          flow.Hour02,
          flow.Hour03,
          flow.Hour04,
          flow.Hour05,
          flow.Hour06,
          flow.Hour07,
          flow.Hour08,
          flow.Hour09,
          flow.Hour10,
          flow.Hour11,
          flow.Hour12,
          flow.Hour13,
          flow.Hour14,
          flow.Hour15,
          flow.Hour16,
          flow.Hour17,
          flow.Hour18,
          flow.Hour19,
          flow.Hour20,
          flow.Hour21,
          flow.Hour22,
          flow.Hour23
        ];

        barChart.data.datasets[0].data.forEach((item, index) => {
          const colorIndex = index % 2 === 0 ? 0 : 1;
          barChart.data.datasets[0].backgroundColor.push(
            chartBackgroundColors[colorIndex]
          );

          barChart.data.datasets[0].borderColor.push(borderColors[colorIndex]);
        });
      }
    });

    onMounted(async () => {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      try {
        peopleFlows.value = await model?.dispatch("peopleFlow/statistics", {
          condition: new Condition("Date", Operator.Equal, today),
          sortings: [{ column: "CumePersons", order: 1 }]
        });

        pieChart.data.labels = [];
        pieChart.data.datasets[0].data = [];
        for (let item of peopleFlows.value) {
          pieChart.data.labels.push(item.Hotspot.Name);
          pieChart.data.datasets[0].data.push(item.CumePersons);
          if (item.Hotspot.Latitude && item.Hotspot.Longitude)
            heatmapPoints.value.push([
              item.Hotspot.Latitude,
              item.Hotspot.Longitude,
              item.SnapshotCount / item.Hotspot.Capacity
            ]);
        }
        const flow = peopleFlows.value[0];
        selectedHotspotId.value = flow.HotspotId;
        console.log("lastHourStr", lastHourStr);
        console.log("lastHourPaddingStr", lastHourPaddingStr);
      } catch {
        peopleFlows.value = [];
      } finally {
        load.isLoading = false;
      }
    });

    const previewModal = reactive({
      visible: false,
      selectedItem: null as PeopleFlow | null,
      show(item: PeopleFlow) {
        previewModal.selectedItem = item;
        previewModal.visible = true;
      }
    });

    const panTo = (item: PeopleFlow) => {
      selectedHotspotIdOnMap.value = item.HotspotId;
      (map.value as any).instance.panTo([
        item.Hotspot.Latitude,
        item.Hotspot.Longitude
      ]);
    };

    return {
      map,
      pieChart,
      barChart,
      peopleFlows,
      heatmapPoints,
      load,
      selectedHotspotId,
      selectedHotspotIdOnMap,
      COLOR_PALETTE,
      previewModal,
      panTo,
      lastHourStr,
      lastHourPaddingStr,
      nowHourStr
    };
  }
});
