<template>
  <div>
    <div
      class="col-span-12 xxl:col-span-9 grid grid-cols-12 gap-6"
      style="position:relative"
    >
      <loading v-model:active="load.isLoading" :is-full-page="load.fullPage" />
      <!-- BEGIN: pie -->
      <div class="col-span-12 sm:col-span-12 xl:col-span-6 mt-6">
        <div class="intro-y overflow-auto lg:overflow-visible mt-8 sm:mt-0">
          <div id="pie-chart" class="p-5">
            <div class="preview">
              <PieChart v-bind="pieChart" />
            </div>
          </div>
        </div>
      </div>
      <!-- END: pie -->
      <!-- BEGIN: line -->
      <div class="col-span-12 sm:col-span-12 xl:col-span-6 mt-6">
        <div class="intro-y overflow-auto lg:overflow-visible mt-8 sm:mt-0">
          <div class="flex items-center">
            <label>選擇地點</label>
            <vxe-select v-model="selectedHotspotId" class="flex-1">
              <vxe-option
                v-for="item in peopleFlows.map(e => e.Hotspot)"
                :key="'hotspot-' + item.Id"
                :value="item.Id"
                :label="item.Name"
              ></vxe-option>
            </vxe-select>
          </div>
          <div id="line-chart" class="p-5">
            <div class="preview">
              <BarChart v-bind="barChart" />
            </div>
          </div>
        </div>
      </div>
      <!-- END: line -->
      <!-- BEGIN: map -->
      <div class="col-span-12 sm:h-96 mt-6">
        <Map ref="map" :center="[23.55407, 119.6258871]">
          <MapTile />
          <!-- <MapMarker v-for="(item, index) in peopleFlows.map(e => e.Hotspot)" :key="'map-marker-' + index"
            :coordinates="[item.Latitude, item.Longitude]" :title="item.Name"
            @click="selectedHotspotIdOnMap = item.Id" /> -->
          <MapHeatmap :points="heatmapPoints" />
        </Map>
      </div>
      <!-- END: map -->
      <!-- BEGIN: hotspots -->
      <div class="col-span-12 mt-6">
        <div class="grid grid-cols-12 gap-6 mt-5">
          <div
            v-for="(item, index) in peopleFlows"
            class="col-span-6 intro-y"
            :key="'people-flow-' + index"
            @click="panTo(item)"
          >
            <div class="report-box zoom-in">
              <div
                class="box p-5"
                :style="{
                  'background-color':
                    selectedHotspotIdOnMap === item.HotspotId
                      ? 'lightblue'
                      : 'white'
                }"
              >
                <div class="sm:flex flex-wrap justify-between">
                  <div class="flex">
                    <FontAwesome
                      icon="street-view"
                      class="report-box__icon mr-3"
                      :style="{ color: COLOR_PALETTE[index] }"
                    />
                    <div class="text-lg font-medium sm:truncate sm:mr-3">
                      {{ item.Ivar.Name }}
                    </div>
                  </div>
                  <div
                    class="text-lg font-medium sm:truncate sm:mr-3"
                    @click.stop="previewModal.show(item)"
                  >
                    平面圖
                  </div>
                </div>
                <div class="mt-3 mb-1 small">
                  <!-- 顯示前一個小時到現在小時整點的時間 -->
                  {{ lastHourStr }}
                  <!-- 21:00~2200 -->
                  <!-- 進出分開顯示 前台的寫法 {{ data?.['ForwardHour' + data.LastHourPaddingStr] }} -->
                  <!-- LastHourPaddingStr是字串型態為24小時制的時間小時部分 00~23 配合 ForwardHour 會組成 ForwardHour22 以取得對應小時的人數 -->
                  <span class=" pl-2">
                   累計進入 {{ item[`ForwardHour${lastHourPaddingStr}`] }} 人次
                  </span>
                  ，離開
                  <span>
                    {{ item[`BackwardHour${lastHourPaddingStr}`] }} 人次
                  </span>
                </div>
                <div class="mt-3 mb-1 small">
                  <!-- 顯示目前時間整點到現在 -->
                  {{ nowHourStr }}
                  <span class="pl-2">預估現場人次：</span>
                  <span class=""> {{ item.SnapshotMinuteCount }} </span>
                </div>
                <div class="mb-3 small">
                  今日累計進入:
                  <span> {{ item.ForwardCount }} 人次 </span>
                  ，累計離開
                  <span> {{ item.BackwardCount }} 人次 </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- END: hotspots -->
    </div>
  </div>
  <vxe-modal
    v-model="previewModal.visible"
    title="平面圖"
    :z-index="1010"
    width="60%"
    height="40%"
  >
    <div class="w-full h-full">
      <img :src="previewModal.selectedItem?.Hotspot.Planimetric?.Uri" />
    </div>
  </vxe-modal>
</template>

<script lang="ts">
import { defineComponent, ref, watch, onMounted, reactive } from "vue";
import CloudFun, { Condition, Operator } from "@cloudfun/core";
import PieChart from "@/components/pie-chart/Main.vue";
import BarChart from "@/components/bar-chart/Main.vue";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import Map from "@/components/map/Main.vue";
import MapTile from "@/components/map/Tile.vue";
import MapHeatmap from "@/components/map/Heatmap.vue";
import { PeopleFlow, COLOR_PALETTE } from ".";
import router from "@/router";

export default defineComponent({
  components: {
    PieChart,
    BarChart,
    Loading,
    Map,
    MapTile,
    MapHeatmap
  },
  setup() {
    if (!CloudFun.user.Id) router.push("/login");
    const model = CloudFun.current?.model;
    const map = ref(null);
    const selectedHotspotId = ref(0);
    const selectedHotspotIdOnMap = ref(0);
    const peopleFlows = ref<PeopleFlow[]>([]);
    const heatmapPoints = ref<number[][]>([]);

    const now = ref(new Date());
    const lastHourStr = `${now.value.getHours() -
      1}:00~${now.value.getHours()}:00`;
    const lastHourPaddingStr = (now.value.getHours() - 1)
      .toString()
      .padStart(2, "0");
    const nowHourStr = `${now.value.getHours()}:00~${now.value
      .getHours()
      .toString()
      .padStart(2, "0")}:${now.value
      .getMinutes()
      .toString()
      .padStart(2, "0")}`;

    const load = reactive({
      isLoading: true,
      fullPage: false
    });

    const pieChart = reactive({
      width: 0,
      height: 0,
      data: {
        labels: [] as string[],
        datasets: [
          {
            data: [] as number[],
            backgroundColor: COLOR_PALETTE,
            // hoverBackgroundColor: ["#26a1ff", "#ff2626"],
            borderWidth: 5,
            borderColor: cash("html").hasClass("dark") ? "#303953" : "#fff"
          }
        ]
      }
    });

    const chartBackgroundColors = [
      "rgba(255, 99, 132, 0.2)",
      "rgba(75, 192, 192, 0.2)"
    ];

    const borderColors = ["rgb(255, 99, 132)", "rgb(75, 192, 192)"];

    const barChart = reactive({
      width: 0,
      height: 0,
      data: {
        labels: [
          "00",
          "01",
          "02",
          "03",
          "04",
          "05",
          "06",
          "07",
          "08",
          "09",
          "10",
          "11",
          "12",
          "13",
          "14",
          "15",
          "16",
          "17",
          "18",
          "19",
          "20",
          "21",
          "22",
          "23"
        ],
        datasets: [
          {
            label: "人數",
            data: [] as any[],
            borderWidth: 1,
            borderColor: [] as string[],
            backgroundColor: [] as string[],
            pointBorderColor: "transparent"
          }
        ]
      }
    });

    watch(selectedHotspotId, id => {
      if (!id) return;

      const flow = peopleFlows.value.find(e => e.HotspotId === id);
      console.log("🚀 ~ file: Daily.vue ~ line 234 ~ setup ~ flow", flow);

      if (flow) {
        barChart.data.datasets[0].data = [
          flow.Hour00,
          flow.Hour01,
          flow.Hour02,
          flow.Hour03,
          flow.Hour04,
          flow.Hour05,
          flow.Hour06,
          flow.Hour07,
          flow.Hour08,
          flow.Hour09,
          flow.Hour10,
          flow.Hour11,
          flow.Hour12,
          flow.Hour13,
          flow.Hour14,
          flow.Hour15,
          flow.Hour16,
          flow.Hour17,
          flow.Hour18,
          flow.Hour19,
          flow.Hour20,
          flow.Hour21,
          flow.Hour22,
          flow.Hour23
        ];

        barChart.data.datasets[0].data.forEach((item, index) => {
          const colorIndex = index % 2 === 0 ? 0 : 1;
          barChart.data.datasets[0].backgroundColor.push(
            chartBackgroundColors[colorIndex]
          );

          barChart.data.datasets[0].borderColor.push(borderColors[colorIndex]);
        });
      }
    });

    onMounted(async () => {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      try {
        peopleFlows.value = await model?.dispatch("peopleFlow/statistics", {
          condition: new Condition("Date", Operator.Equal, today),
          sortings: [{ column: "CumePersons", order: 1 }]
        });

        pieChart.data.labels = [];
        pieChart.data.datasets[0].data = [];
        for (let item of peopleFlows.value) {
          pieChart.data.labels.push(item.Hotspot.Name);
          pieChart.data.datasets[0].data.push(item.CumePersons);
          if (item.Hotspot.Latitude && item.Hotspot.Longitude)
            heatmapPoints.value.push([
              item.Hotspot.Latitude,
              item.Hotspot.Longitude,
              item.SnapshotCount / item.Hotspot.Capacity
            ]);
        }
        const flow = peopleFlows.value[0];
        selectedHotspotId.value = flow.HotspotId;
        console.log("lastHourStr", lastHourStr);
        console.log("lastHourPaddingStr", lastHourPaddingStr);
      } catch {
        peopleFlows.value = [];
      } finally {
        load.isLoading = false;
      }
    });

    const previewModal = reactive({
      visible: false,
      selectedItem: null as PeopleFlow | null,
      show(item: PeopleFlow) {
        previewModal.selectedItem = item;
        previewModal.visible = true;
      }
    });

    const panTo = (item: PeopleFlow) => {
      selectedHotspotIdOnMap.value = item.HotspotId;
      (map.value as any).instance.panTo([
        item.Hotspot.Latitude,
        item.Hotspot.Longitude
      ]);
    };

    return {
      map,
      pieChart,
      barChart,
      peopleFlows,
      heatmapPoints,
      load,
      selectedHotspotId,
      selectedHotspotIdOnMap,
      COLOR_PALETTE,
      previewModal,
      panTo,
      lastHourStr,
      lastHourPaddingStr,
      nowHourStr
    };
  }
});
</script>
