
import { defineComponent, inject, Ref, onMounted, onBeforeUnmount, PropType } from "vue";
import { MapSymbol } from "./utils";

export default defineComponent({
  name: "Heatmap",
  props: {
    points: {
      type: Array as PropType<number[][]>,
      require: true
    },
    options: {
      type: Object as PropType<{
        minOpacity?: number;
        maxZoom?: number;
        max?: number;
        radius?: number;
        blur?: number;
        gradient?: { [key: number]: string };
      }>,
      default: () => ({
        radius: 25,
        // max: 1,
        maxZoom: 12,
        gradient: { 0.6: 'green', 0.9: 'orange', 1: 'red' },
      })
    }
  },
  setup(props) {
    const map = inject<Ref<any>>(MapSymbol)!;
    let layer: any;

    onMounted(async () => {
      await import("leaflet.heat"); // add heatLayer to leaflet
      const { default: L } = await import("leaflet");

      layer = (L as any).heatLayer(props.points, props.options).addTo(map.value);

    });

    onBeforeUnmount(() => {
      if (layer) map.value.removeLayer(layer);
    });

    return {};
  },
});
