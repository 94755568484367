export interface Hotspot {
  Id: number;
  Name: string;
}

export interface PeopleFlow {
  Date: string;
  HolidayString?: string;
  HotspotId: number;
  Hotspot: {
    Id: number;
    Name: string;
    Cover?: { Uri: string };
    Planimetric?: { Uri: string };
    Latitude?: number;
    Longitude?: number;
    LinkUrl?: string;
    Capacity: number;
  };
  Hour00: number;
  Hour01: number;
  Hour02: number;
  Hour03: number;
  Hour04: number;
  Hour05: number;
  Hour06: number;
  Hour07: number;
  Hour08: number;
  Hour09: number;
  Hour10: number;
  Hour11: number;
  Hour12: number;
  Hour13: number;
  Hour14: number;
  Hour15: number;
  Hour16: number;
  Hour17: number;
  Hour18: number;
  Hour19: number;
  Hour20: number;
  Hour21: number;
  Hour22: number;
  Hour23: number;
  SnapshotCount: number;
  CumePersons: number;
  // for display add By Leo 2022/10/29
  ForwardHour00: number;
  ForwardHour01: number;
  ForwardHour02: number;
  ForwardHour03: number;
  ForwardHour04: number;
  ForwardHour05: number;
  ForwardHour06: number;
  ForwardHour07: number;
  ForwardHour08: number;
  ForwardHour09: number;
  ForwardHour10: number;
  ForwardHour11: number;
  ForwardHour12: number;
  ForwardHour13: number;
  ForwardHour14: number;
  ForwardHour15: number;
  ForwardHour16: number;
  ForwardHour17: number;
  ForwardHour18: number;
  ForwardHour19: number;
  ForwardHour20: number;
  ForwardHour21: number;
  ForwardHour22: number;
  ForwardHour23: number;

  BackwarHour00: number;
  BackwarHour01: number;
  BackwarHour02: number;
  BackwarHour03: number;
  BackwarHour04: number;
  BackwarHour05: number;
  BackwarHour06: number;
  BackwarHour07: number;
  BackwarHour08: number;
  BackwarHour09: number;
  BackwarHour10: number;
  BackwarHour11: number;
  BackwarHour12: number;
  BackwarHour13: number;
  BackwarHour14: number;
  BackwarHour15: number;
  BackwarHour16: number;
  BackwarHour17: number;
  BackwarHour18: number;
  BackwarHour19: number;
  BackwarHour20: number;
  BackwarHour21: number;
  BackwarHour22: number;
  BackwarHour23: number;

  LastHourString: string;
  LastHourPaddingStr: string;

}

export const COLOR_PALETTE = [
  "#f27059",
  "#ff8800",
  "#e9ff70",
  "#a1c181",
  "#33a1fd",
  "#392f5a",
  "#822faf"
];
